import { template as template_73f3062d3eb24fa7bd2ad2cc6597d1fd } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_73f3062d3eb24fa7bd2ad2cc6597d1fd(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
