import { template as template_26c13a62664646d59e84fe36e0b327ed } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_26c13a62664646d59e84fe36e0b327ed(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
