import { template as template_3bcd3ccd8c1845d7ab77dd7780f8adf2 } from "@ember/template-compiler";
const FKLabel = template_3bcd3ccd8c1845d7ab77dd7780f8adf2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
